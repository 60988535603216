import React from 'react'

const Home = () => {

  return (
    <div className="ui inverted segment">
      <div className="ui inverted header">
        <h1>Welcome to HiVO</h1>
      </div>

    </div>
  )
}

export default Home